import useJwt from '@core/auth/jwt/useJwt'
import axios from '@/libs/axios'
import { tiktokIns } from '@/libs/axios'

const { jwt } = useJwt(axios, {
  loginEndpoint: '/login',
  registerEndpoint: '/register',
  refreshEndpoint: '/refresh-token',
  logoutEndpoint: '/logout',
})

tiktokIns.interceptors.request.use((config) => {
  const token = jwt.getToken()
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

tiktokIns.interceptors.response.use(response => response, (error) => {
  if (error?.response?.status === 401) {
    return jwt.refreshToken().then(() => {
      return tiktokIns
        .request(error.config)
    })
      .catch((error) => {
        return Promise.reject(error)
      })
  }
  return Promise.reject(error)
})

export default jwt
