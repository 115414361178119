export default [
  {
    path: '/tiktok/brands',
    name: 'tiktok.brands.index',
    component: () => import('@/views/tiktok/TikTokBrands.vue'),
    meta: {
      pageTitle: 'TikTok Brands',
      breadcrumb: [
        {
          text: 'TikTok Brands',
          active: true,
        },
      ],
      action: 'create',
      resource: 'TikTok',
    },
  },
  {
    path: '/tiktok/brands/:id',
    name: 'tiktok.brand.info.index',
    props: true,
    component: () => import('@/views/tiktok/TikTokBrandInfo.vue'),
    meta: {
      pageTitle: 'Brand Info',
      breadcrumb: [
        {
          text: 'TikTok Brands List',
          to: { name: 'tiktok.brands.index' },
        },
        {
          text: 'Brand Info',
          active: true,
        },
      ],
      action: 'create',
      resource: 'TikTok',
    },
  },
  {
    path: '/tiktok/creators',
    name: 'tiktok.creators.index',
    component: () => import('@/views/tiktok/TikTokCreators.vue'),
    meta: {
      pageTitle: 'TikTok Creators',
      breadcrumb: [
        {
          text: 'TikTok Creators',
          active: true,
        },
      ],
      action: 'create',
      resource: 'TikTok',
    },
  },
  {
    path: '/tiktok/keywords',
    name: 'tiktok.keywords.index',
    component: () => import('@/views/tiktok/TikTokKeywords.vue'),
    meta: {
      pageTitle: 'TikTok Keywords',
      breadcrumb: [
        {
          text: 'TikTok Keywords',
          active: true,
        },
      ],
      action: 'create',
      resource: 'TikTok',
    },
  },
  {
    path: '/tiktok/report-uploader',
    name: 'tiktok.report-uploader.list.index',
    component: () => import('@/views/tiktok/report-uploader/list/Index.vue'),
    meta: {
      pageTitle: 'TikTok Report Uploader',
      breadcrumb: [
        {
          text: 'TikTok Report Uploader',
          active: true,
        },
      ],
      action: 'read',
      resource: 'TikTokUploader',
    },
  },
  {
    path: '/tiktok/report-uploader/:report/upload',
    name: 'tiktok.report-uploader.upload.index',
    props: true,
    component: () => import('@/views/tiktok/report-uploader/upload/Index.vue'),
    meta: {
      pageTitle: 'TikTok Report Uploader',
      breadcrumb: [
        {
          text: 'TikTok Report Uploader',
          to: { name: 'tiktok.report-uploader.list.index' },
        },
        {
          text: 'Upload',
          active: true,
        },
      ],
      action: 'read',
      resource: 'TikTokUploader',
    },
  },
]
