import {
  ApolloClient, HttpLink, InMemoryCache, ApolloLink, Observable, from, split,
} from '@apollo/client/core/index'
import { onError } from '@apollo/client/link/error/index'
import jwtConfig from '@/auth/jwt/useJwt'
import axios from '@/libs/axios'
import { cloneDeepWith } from 'lodash'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions/index'
import { getMainDefinition } from '@apollo/client/utilities/index'
import { createClient, CloseCode } from 'graphql-ws'

async function refreshAccessToken() {
  return axios.post(jwtConfig.getConfig().refreshEndpoint, {
    refreshToken: jwtConfig.getRefreshToken(),
  })
    .then(response => {
      const {
        accessToken,
        refreshToken,
      } = response.data
      jwtConfig.setToken(accessToken)
      jwtConfig.setRefreshToken(refreshToken)
      return response
    })
}

const errorMiddleware = onError(({
  graphQLErrors,
  forward,
  operation,
// eslint-disable-next-line consistent-return
}) => {
  if (graphQLErrors) {
    // eslint-disable-next-line no-restricted-syntax
    for (const err of graphQLErrors) {
      // eslint-disable-next-line default-case
      switch (err?.extensions?.code) {
        case 'UNAUTHENTICATED': {
          return new Observable(observer => {
            refreshAccessToken()
              .then(response => {
                const { accessToken } = response.data
                const oldHeaders = operation.getContext().headers
                operation.setContext({
                  headers: {
                    ...oldHeaders,
                    authorization: `${jwtConfig.getConfig().tokenType} ${accessToken}`,
                  },
                })
              })
              .then(() => {
                const subscriber = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer),
                }

                forward(operation)
                  .subscribe(subscriber)
              })
              .catch(e => {
                observer.error(e)
                jwtConfig.clear()
                window.location = '/login'
              })
          })
        }
      }
    }
  }
})

const httpLink = new HttpLink({
  uri: '/tgql',
})

let shouldRefreshToken = false
// the socket close timeout due to token expiry
let tokenExpiryTimeout = null
// let attempts = 0

const wsLink = new GraphQLWsLink(createClient({
  url: process.env.VUE_APP_TIKTOK_SUBSCRIPTIONS_URL || '/twsgql',
  retryAttempts: 10,
  connectionAckWaitTimeout: 5000,
  connectionParams: async () => {
    if (shouldRefreshToken) {
      // refresh the token because it is no longer valid
      await refreshAccessToken()
      // and reset the flag to avoid refreshing too many times
      shouldRefreshToken = false
    }
    return { token: jwtConfig.getToken() }
  },
  on: {
    connected: socket => {
      clearTimeout(tokenExpiryTimeout)

      // set a token expiry timeout for closing the socket
      // with an `4403: Forbidden` close event indicating
      // that the token expired. the `closed` event listner below
      // will set the token refresh flag to true
      tokenExpiryTimeout = setTimeout(() => {
        if (socket.readyState === WebSocket.OPEN) {
          socket.close(CloseCode.Forbidden, 'Forbidden')
        }
      }, jwtConfig.getTokenExpiration())
    },
    closed: event => {
      // if closed with the `4403: Forbidden` close event
      // the client or the server is communicating that the token
      // is no longer valid and should be therefore refreshed
      if (event.code === CloseCode.Forbidden) shouldRefreshToken = true
    },
  },
}))

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition'
      && definition.operation === 'subscription'
    )
  },
  wsLink,
  httpLink,
)

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: `${jwtConfig.getConfig().tokenType} ${jwtConfig.getToken()}`,
    },
  }))

  return forward(operation)
})

const removeTypename = new ApolloLink((operation, forward) => {
  const newOperation = operation
  newOperation.variables = cloneDeepWith(newOperation?.variables ?? {}, value => {
    // eslint-disable-next-line no-underscore-dangle
    if (value && value.__typename) {
      const {
        __typename,
        ...valWithoutTypename
      } = value
      return valWithoutTypename
    }

    return undefined
  })
  return forward(newOperation)
})

const cache = new InMemoryCache({
  addTypename: true,
})

const apolloClient = new ApolloClient({
  link: from([
    removeTypename,
    authMiddleware,
    errorMiddleware,
    link,
  ]),
  cache,
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only',
    },
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
  connectToDevTools: process.env.NODE_ENV === 'development',
})

export default apolloClient
